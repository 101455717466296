<script setup>
  import { computed, ref, onBeforeMount, watch } from "vue";
  import { useRouter } from "vue-router";
  import { storeToRefs } from "pinia";
  import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
  import { ChevronRightIcon, UsersIcon } from "@heroicons/vue/24/outline";
  import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import UserAvatar from "@/components/Atoms/UserAvatar.vue";
  import { useClientStore, useSearchStore } from "@/stores";
  import MessageUserButton from "../Atoms/MessageUserButton.vue";

  const searchStore = useSearchStore();
  const clientStore = useClientStore();
  const { clients } = storeToRefs(clientStore);
  const { recentSearches, dialogOpen } = storeToRefs(searchStore);
  const router = useRouter();
  const query = ref("");
  const clientSearchInput = ref(null);

  const filteredClients = computed(() => {
    const values = clients.value.filter((client) => {
      return client.fullName.toLowerCase().includes(query.value.toLowerCase());
    });
    return query.value === "" ? [] : values;
  });

  function onSelect(client) {
    console.log("onselect", client);
    if (!recentSearches.value.includes(client)) {
      recentSearches.value.unshift(client);
      recentSearches.value = recentSearches.value.slice(0, 3);
    }
    closeDialog();
    router.push({ name: "ViewClientPage", params: { id: client.id } });
  }

  function focusInput() {
    if (clientSearchInput.value) {
      clientSearchInput.value.focus();
    }
  }

  function closeDialog() {
    searchStore.dialogOpen = false;
  }


  watch(dialogOpen, (value) => {
    if (value) {
      focusInput();
    }
  });

  onBeforeMount(() => {
    clientStore.fetchClients();
  });

</script>
<template>
  <TransitionRoot
    :show="dialogOpen"
    as="template"
    appear
    @after-leave="query = ''"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="closeDialog"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-3xl mt-14 md:mt-2 transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox
              v-slot="{ activeOption }"
              @update:model-value="onSelect"
            >
              <div class="relative">
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  ref="clientSearchInput"
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  @change="query = $event.target.value"
                />
              </div>

              <ComboboxOptions
                v-if="query === '' || filteredClients.length > 0"
                class="flex divide-x divide-gray-100"
                as="div"
                static
                hold
              >
                <div
                  :class="['max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96']"
                >
                  <h2
                    v-if="query === ''"
                    class="mb-4 mt-2 text-xs font-semibold text-gray-500"
                  >
                    Recent searches
                  </h2>
                  <div
                    hold
                    class="-mx-2 text-sm text-gray-700"
                  >
                    <ComboboxOption
                      v-for="client in query === '' ? recentSearches : filteredClients"
                      :key="client.id"
                      v-slot="{ active }"
                      :value="client"
                      as="template"
                    >
                      <div
                        :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']"
                      >
                        <UserAvatar
                          :user="client"
                          size="7"
                        />
                        <span class="ml-3 flex-auto truncate">{{
                          client.fullName
                        }}</span>
                        <ChevronRightIcon
                          v-if="active"
                          class="ml-3 h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </ComboboxOption>
                  </div>
                </div>

                <div
                  v-if="activeOption"
                  class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex"
                >
                  <div class="flex-none p-6 text-center">
                    <UserAvatar
                      :user="activeOption"
                      size="16"
                    />
                    <h2 class="mt-3 font-semibold text-gray-900">
                      {{ activeOption.fullName }}
                    </h2>
                  </div>
                  <div class="flex flex-auto flex-col justify-between p-6">
                    <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <dt class="col-end-1 font-semibold text-gray-900">
                        Phone
                      </dt>
                      <dd> {{ activeOption.phoneNumber }} </dd>
                      <dt class="col-end-1 font-semibold text-gray-900">
                        Email
                      </dt>
                      <dd class="truncate">
                        <a
                          :href="`mailto:${activeOption.email}`"
                          class="text-indigo-600 underline"
                        >
                          {{ activeOption.email }}
                        </a>
                      </dd>
                    </dl>
                    <MessageUserButton :user-id="activeOption.id" />
                  </div>
                </div>
              </ComboboxOptions>

              <div
                v-if="query !== '' && filteredClients.length === 0"
                class="px-6 py-14 text-center text-sm sm:px-14"
              >
                <UsersIcon
                  class="mx-auto h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <p class="mt-4 font-semibold text-gray-900">
                  No people found
                </p>
                <p class="mt-2 text-gray-500">
                  We couldn’t find anything with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
