<script setup>
  import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
  import { useSearchStore } from "@/stores";

  const searchStore = useSearchStore();
</script>

<template>
  <button
    type="button"
    class="w-full inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md bg-gray-700 text-gray-400"
    @click="searchStore.toggleDialog()"
  >
    <MagnifyingGlassIcon
      class="-ml-0.5 mr-2 h-5 w-5"
      aria-hidden="true"
    />
    Search Clients
  </button>
</template>
